import TAP from '../models/entities/TAP';
import TAPDelegated from '../models/entities/TAPDelegation';
import ApiClient from './ApiClient';

interface GetTAPResponse {
	requests: TAP[];
	approvals: TAP[];
	onBehalfOf:TAP[];
	nonApprovedTaps: number;
}

export default class TemporaryAccessPasswordService extends ApiClient {
	
	async getAll() {
		return this.get<GetTAPResponse>('TAP/me');
	}

	async getSupVerifycation(email:string) {
		return this.get<string>(`TAP/verifySupervisor?userPrincipalName=${email}`);
	}

	async getDelegatedVerifycation(email:string) {
		return this.get<string>(`TAP/verifyDelegated?userPrincipalName=${email}`);
	}

	async myApprovals(email:string) {
		return this.get<string>(`TAP/verifySupervisor?userPrincipalName=${email}`);
	}

	async GetTapInfoOnBehalfOf(email:string) {
		return this.get<TAP[]>(`TAP/onBehalf/${email}`);
	}

	async createTap() {
		return this.post<TAP>('TAP', null);
	}

	async createOnBehalfOfTap(id:string) {
		return this.post<TAP>(`TAP/onBehalf/${id}`, null);
	}

	async completeTap(tapId: number, approved: boolean) {
		return this.patch<string>(`TAP/${tapId}/complete`, {'Approved': approved});
	}

	async getTapAfterApproved() {
		return this.patch<TAP>(`TAP/newTap`, null);
	}

	async submitTapOnBehafDelegated(delegatedId:string, tapUserId: string) {
		return this.post<TAPDelegated>(`TAP/delegated`,{'Delegated': delegatedId,"TapUser": tapUserId});
	}

	async getTapDelegations() {
		return this.get<TAPDelegated[]>(`TAP/delegated`);
	}

	async createTapOnBehalfOfDelegated(tapUserId: string) {
		return this.post<TAP>(`TAP/onBehalfOf/delegated/${tapUserId}`, {});
	}
	
	async disableDelegated(delegatedId: number) {
		return this.patch<TAP>(`TAP/delegated/${delegatedId}`, null);
	}
	
	async getTapInfoOnBehalfDelegated(delegatedId: string) {
		return this.get<TAP[]>(`TAP/onBehalfOf/delegated/${delegatedId}`);
	} 

}
import { RuleAction } from '../enums/POR/RuleAction';
import { RuleOperation } from '../enums/POR/RuleOperation';
import { PortOpeningRequestsDirection } from '../enums/POR/PortOpeningRequestsDirection';
import { AWSRuleRegion } from '../enums/POR/AWSRuleRegion';

export default class AWSPortOpeningRule {
	id!: number;

	portOpeningRequestId!: number;

	action!: RuleAction;

	operation!: RuleOperation;

	description!: string;

	sourceIPs!: string;

	destinationIPs!: string;

	destinationURLs!: string;

	sourcePorts!: string;

	protocol!: string;

	service!: string;

	name!: string;

	awsName!: string;

	direction!: PortOpeningRequestsDirection;

	destinationPorts!: string;

	region!: AWSRuleRegion;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.direction = new PortOpeningRequestsDirection().fromValue(obj.direction?.value);
	}
}
import User from "../User";
import TAPDelegated from "./TAPDelegation";


export default class TAP {
	id!: number;

	approvalStatus?: string;

	startingTime?: Date;

	createdTime!: Date;

	requestor!: User;

	approver!: User;

	onBehalf!: User;

	delegated!: TAPDelegated;

	tapId!: number;

	taPass?: string;

	constructor(obj: any) {
		if (obj != ''){
			Object.assign(this, obj);
		}
	}
}
import React, { useState } from 'react';
import { Grid, Button, TrashcanIcon, Modal, Alert, XFilledIcon } from 'react-unity';
import TAP from '../../../models/entities/TAP';
import { authenticatedUser } from '../../../authentication/authModule';

interface TAPDelegatedProps {
    tap: any;
    handleRejectTAP: any;
    loading: boolean;
}

const TAPDelegated = ({ tap, handleRejectTAP, loading }: TAPDelegatedProps) => {
    const [cancelDelegateModalVisible, setCancelDelegateModalVisible] = useState(false);

    const handleCancel = () => {
        setCancelDelegateModalVisible(false);
    };

    const handleConfirm = () => {
        handleRejectTAP(tap.id);
        setCancelDelegateModalVisible(false);
    };

    return (
        <>
            <Button
                style={{ zIndex: 0, cursor: 'pointer' }}
                variant="primary"
                size="small"
                onClick={() => setCancelDelegateModalVisible(true)}
                disabled={loading || tap.delegatedStatus === 'Disabled' || authenticatedUser.userId === tap.delegated?.id}
            >
                <TrashcanIcon size="small" />
            </Button>
            <Modal show={cancelDelegateModalVisible} onHide={handleCancel}>
                <Modal.Window>
                    <Modal.Window.Header>
                        <Modal.Window.Header.Title>Cancel Delegation</Modal.Window.Header.Title>
                        <Modal.Window.Header.CloseButton onClick={handleCancel}>
                            <XFilledIcon size='small' />
                        </Modal.Window.Header.CloseButton>
                    </Modal.Window.Header>
                    <Modal.Window.Body>
                        <Alert variant="warning">
                            Are you sure you want to cancel the delegation? This action cannot be undone.
                        </Alert>
                    </Modal.Window.Body>
                    <Modal.Window.Footer>
                        <Grid variant="2-up">
                            <Grid.Item>
                                <Button variant="secondary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </Grid.Item>
                            <Grid.Item>
                                <Button variant="primary" onClick={handleConfirm} disabled={loading}>
                                    Confirm
                                </Button>
                            </Grid.Item>
                        </Grid>
                    </Modal.Window.Footer>
                </Modal.Window>
            </Modal>
        </>
    );
};

export default TAPDelegated;

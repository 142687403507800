import * as React from 'react';
import { Grid, Button, Alert, TextLink } from 'react-unity';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import UserLookup from '../common/form-controls/UserLookup';
import TextAreaField from '../common/form-controls/TextareaField';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import AlertModal from '../common/modals/AlertModal';
import ConfirmModal from '../common/modals/ConfirmModal';
import { RuleOperation } from '../../models/enums/POR/RuleOperation';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactButton from '../common/ReactButton';
import { AbstractAWSPORForm, AbstractAWSPORFormProps, AbstractAWSPORFormState, IAWSPORStateForm } from './components/AbstractAWSPORForm';
import { ApproverInput, EnvironmentInput } from '../PortOpeningRequests/components';
import PORTypeInput from './components/PORTypeInput';
import AWSAccount from '../../models/entities/AWSAccount';
import AWSPortOpeningRequest from '../../models/entities/AWSPortOpeningRequests';
import AWSPortOpeningRule from '../../models/entities/AWSPortOpeningRule';
import { VirtualPrivateCloud } from '../../models/entities/AzureResources/VirtualPrivateCloud';
import AWSAccountLookup from '../common/form-controls/AWSAccountLookup';
import { AWSRulesTable } from './components/AWSRulesTable';
import AWSPortOpeningRequestsService from '../../services/AWSPortOpeningRequestsService';
import SubmitAWSPortOpeningRequest from '../../models/viewModels/PortOpeningRequests/SubmitAWSPortOpeningRequest';
import SubmitAWSPortOpeningRequestRule from '../../models/viewModels/PortOpeningRequests/SubmitAWSPortOpeningRequestRule';

interface AWSPortOpeningRequestsCreateProps extends AWSPortOpeningRequestsCreateWithParamsProps {}

interface AWSPortOpeningRequestsCreateWithParamsProps extends AbstractAWSPORFormProps {
	accountId?: string;
	draftRequest?: AWSPortOpeningRequest;
	validNets?: VirtualPrivateCloud[];
}

interface StateForm extends IAWSPORStateForm {
	selectedScope: string;
}

interface ILocationState {
	selectedRules?: AWSPortOpeningRule[]
}

interface AWSPortOpeningRequestsCreateWithParamsState extends AbstractAWSPORFormState<StateForm> {
	awsAccount: AWSAccount | null;
	cancelModalVisible: boolean;
	changeAccountModalVisible: boolean;
	newScope: string;
	newAccount: AWSAccount;
	implementedRules: AWSPortOpeningRule[];
	virtualPrivateClouds: VirtualPrivateCloud[];
	loading: boolean;
	subToInternetWarningVisible: boolean;
	loadingImplementedRules: boolean;
	failedImplementedRules: boolean;
	selectedFromExisting: string[];
}

class AWSPortOpeningRequestsCreateWithParams extends AbstractAWSPORForm
	<AWSPortOpeningRequestsCreateWithParamsProps, AWSPortOpeningRequestsCreateWithParamsState> {

	awsAccountService = new AWSPortOpeningRequestsService();

	constructor(props) {
		super(props);
		this.state = {
			awsAccount: null,
			stateForm: {
				selectedScope: PortOpeningRequestsDirection.AccountToInternet.name,
				contact: null,
				customerCoordination: null,
				businessJustification: '',
				ruleList: [],
			},
			endModal: {
				visible: false,
			},
			submissionAlert: {
				visible: false,
			},
			submissionWarned: false,
			operationInProgress: false,
			cancelModalVisible: false,
			changeAccountModalVisible: false,
			newScope: null,
			newAccount: null,
			implementedRules: [],
			virtualPrivateClouds: [],
			loading: false,
			subToInternetWarningVisible: true,
			loadingImplementedRules: true,
			failedImplementedRules: false,
			selectedFromExisting: [],
		};
		this.initFormModel();
	}

	async setImplementedRules(account: AWSAccount) {
		try {
			const implementedRules = await this.getPortOpeningRules(account);
			this.setState({
				implementedRules,
				loadingImplementedRules: false,
				failedImplementedRules: false
			});
		} catch (error) {
			this.setState({
				failedImplementedRules: true
			})
			this.handleEndModal('Error while retrieving account info, please try again', 'error', 5000, this.returningRoute());
		}
	}

	async getPortOpeningRules(account: AWSAccount) {
		const portOpeningRules = account.awsPortOpeningRequests.filter(obj => {
			return obj.workflowInstance.currentWorkflowInstanceState.workflowState.name === 'Completed';
		}).flatMap(o => o.awsPortOpeningRules);

		console.log(portOpeningRules)
		const groupedRules = portOpeningRules.reduce((r, a) => {
			r[a.awsName] = [...r[a.awsName.indexOf[-1]] || [], a];
			return r;
		}, {});

		return Object.values(groupedRules).filter((data: any) => data[0].operation.name !== "Remove").map(a => a[0]);
	}

	async componentDidMount() {
		window.scroll(0, 0);
		this.setState({ loading: true })
		if (this.props.draftRequest) {
			await this.loadAccount(this.props.draftRequest?.accountId);
			this.setState({stateForm: this.stateFormFromRequest(this.props.draftRequest)});
		}
		if (this.props.location.state) {
			const {selectedRules}: ILocationState = this.props.location.state;
			this.handleStateFormChange('selectedScope', selectedRules[0].direction.name);
			this.handleStateFormChange('ruleList', selectedRules.map(rule => { return {...rule, operation: RuleOperation.Modify}; }));
			this.setState({
				selectedFromExisting: selectedRules.map(rule => rule.name)
			});
		}
		this.setState({
			loading: false
		});
	}

	async loadAccount(id: Number) {
		try {
			const request = new AWSAccount(await this.getAWSAccount(id));
			this.setState({
				newAccount: request,
				virtualPrivateClouds: request.vpc
			});
			this.stateFormHandler().awsAccount.onChange(request);
			this.handleStateFormChange('accountId', request.account.id);
			this.setImplementedRules(request);
		} catch (error) {
			const HTTP_FORBIDDEN_ERROR_CODE = 403;
			if (error.status === HTTP_FORBIDDEN_ERROR_CODE) {
				this.handleEndModal('You are not allowed to see this application.', 'error', 5000, this.returningRoute());
			} else {
				this.handleEndModal('The application data could not be retrieved.', 'error', 10000, this.returningRoute());
			}
		}
	};

	initFormModel() {
		super.initFormModel();
		this.formModel.addField('direction', {
			getValue: () => this.state.stateForm.selectedScope,
			validation: {
				required: true,
			},
		});

		this.formModel.fields.portOpeningRules.getValue =
			() => this.state.stateForm.ruleList.map(rule => new SubmitAWSPortOpeningRequestRule(rule));

		this.formModel.addField('AWSPortOpeningRequestId', {
			getValue: () => this.props.draftRequest?.id,
		});
	}

	stateFormFromRequest(por: AWSPortOpeningRequest): StateForm {
		let obj = {
			...super.stateFormFromRequest(por),
			selectedScope: por.direction?.name || '',
		};
		return { ...obj, ruleList: obj.ruleList.map(rule => { return { ...rule } }) }
	}

	returningRoute = () => {
		return `/awsPortOpeningRequest/connectedAWS`;
	};

	handleCancel = () => {
		this.setSubmissionAlertInProgress('Cancelling...');
		this.setState({
			cancelModalVisible: false,
		}, async () => {
			try {
				await this.awsPortOpeningRequestService.cancel(this.props.draftRequest?.id, {
					cancellationReason: 'Draft dismissed',
				});
				this.handleEndModal(
					'Request cancelled sucessfully.',
					'success',
					5000,
					this.returningRoute()
				);
			} catch (err) {
				this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
			} finally {
				this.setState({
					operationInProgress: false,
				});
			}
		});
	};

	handleSubmit = async () => {
		this.setSubmissionAlertInProgress('Submitting your request...');
		if (this.requiresApproverVerification(this.state.stateForm.selectedScope)) {
			const isVerified = await this.approverLevelVerified();
			if (!isVerified)
				return;
		}
		try {
			const model = this.formModel.create(SubmitAWSPortOpeningRequest);
			const request = await this.awsPortOpeningRequestService.create(model);
			this.handleEndModal(
				`Your request was submitted successfully. You will receive a notification via email once the process has finished.`,
				'success',
				5000,
				this.returningRoute()
			);
		} catch (err) {
			this.setSubmissionAlertError(err?.response?.data.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
			});
		}
	};

	handleUpdate = async () => {
		this.setSubmissionAlertInProgress('Saving changes...');
		try {
			const model = this.formModel.create(SubmitAWSPortOpeningRequest);
			const request = await this.awsPortOpeningRequestService.saveDraft(model);
			this.handleEndModal(
				`Request #${request.id} was saved successfully.`,
				'success',
				5000,
				this.returningRoute()
			);
		} catch (err) {
			this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
			});
		}
	};

	handleAccountChange = (event: AWSAccount) => {
		if (this.state.stateForm.ruleList.length > 0 && !this.state.newAccount) {
			this.setState({ changeAccountModalVisible: true, newAccount: event });
		} else {
			this.stateFormHandler().awsAccount.onChange(event);
			this.setState({
				virtualPrivateClouds: event.vpc,
				newAccount: null
			});
			this.setImplementedRules(event);
			this.handleStateFormChange('accountId', event.account.id);
			this.handleStateFormChange('approver', null);
			this.handleStateFormChange('delegatedApprover', null);
		}
	};

	confirmAccountChange = () => {
		this.stateFormHandler().awsAccount.onChange(this.state.newAccount);
		this.handleStateFormChange('accountId', this.state.newAccount.account.id);
		this.handleStateFormChange('ruleList', []);
		this.handleStateFormChange('approver', null);
		this.setState({
			changeAccountModalVisible: false,
			virtualPrivateClouds: this.state.newAccount.vpc,
			newAccount: null,
		});
	};

	ruleIsAddedAlready(rule: AWSPortOpeningRule): boolean {
		return this.state.stateForm.ruleList
			.map(editedRule => editedRule.awsName).includes(rule.awsName);
	}

	getImplementedRulesToShow(): AWSPortOpeningRule[] {
		return this.state.implementedRules.filter(rule =>
			!this.ruleIsAddedAlready(rule)
			&& !this.state.selectedFromExisting.includes(rule.name));
	}

	setSelectedFromExisting = (rulesName: string[]) => {
		this.setState({
			selectedFromExisting: rulesName
		})
	}

	isValid = () => {
		return (this.formModel.isValid() && (this.props.draftRequest || !this.wasNotEdited()))
	}

	wasNotEdited = () => {
		return (this.state.stateForm.ruleList.some((rule) => rule.awsName != undefined));
	}

	render() {
		return (
			<>

				<RequestFormWrapper
					title={"New AWS Port Opening Request"}
					loading={this.state.loading}
				>
					<p>
						For more information about AWS PORs {' '}
						{/*TODO: Add link to wiki*/}
						<TextLink className='em-u-margin-right-none' external target="_blank" href=''>
							Click here
						</TextLink>
					</p>
					{this.state.submissionAlert.visible &&
						<Alert
							variant={this.state.submissionAlert.variant}
							onClose={this.handleSubmissionAlertClose}
						>
							{this.state.submissionAlert.text}
						</Alert>}
						<Grid variant="halves">
							<Grid.Item>
								<AWSAccountLookup
									{...this.stateFormHandler().awsAccount}
									onAccountChange={this.handleAccountChange}
									newAccount={this.state.newAccount}
									disabled={this.state.operationInProgress}
								/>
							</Grid.Item>
						</Grid>
					<Grid variant="halves">
						<Grid.Item>
							<PORTypeInput
								scope={{
									value: this.state.stateForm.selectedScope,
									validation: this.formModel.fields.direction.validation,
									onChange: (event) => this.handleStateFormChange('selectedScope', event.target.value),
									disabled: this.state.operationInProgress ,
								}}
							/>
						</Grid.Item>
							<Grid.Item>
								<EnvironmentInput
									{...this.stateFormHandler().environment}
									disabled={this.state.operationInProgress }
								/>
							</Grid.Item>
					</Grid>
					<Grid variant="halves">
						<Grid.Item>
							<UserLookup
								label="Technical contact"
								{...this.stateFormHandler().contact}
								disabled={this.state.operationInProgress }
							/>
						</Grid.Item>
							<Grid.Item>
								<ApproverInput
									{...this.stateFormHandler().approver}
									disabled={this.state.operationInProgress }
									scope={this.state.stateForm.selectedScope}
								/>
							</Grid.Item>
					</Grid>
					<TextAreaField
						label="Business Justification"
						{...this.stateFormHandler().businessJustification}
						disabled={this.state.operationInProgress}
					/>
					<AWSRulesTable
							{...this.stateFormHandler().ruleset}
							currentWorkflowState={this.props.draftRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value}
							porDirection={new PortOpeningRequestsDirection().fromName(this.state.stateForm.selectedScope)}
							implementedRules={this.getImplementedRulesToShow()}
							readonly={this.state.operationInProgress}
							virtualPrivateClouds={this.state.virtualPrivateClouds}
							loadingImplementedRules={this.state.loadingImplementedRules}
							failedImplementedRules={this.state.failedImplementedRules}
							selectedFromExisting={this.state.selectedFromExisting}
							setSelectedFromExisting={this.setSelectedFromExisting}
					/>
					{this.state.awsAccount == null && (
						<TextAreaField
							label="Additional Comments"
							{...this.stateFormHandler().comments}
							disabled={this.state.operationInProgress }
						/>
					)}
					<Grid variant="4-up">
						<Grid.Item>
							{!!this.props.draftRequest && this.props.draftRequest?.isCancellable() &&
								<Button
									variant="secondary"
									onClick={() => {
										this.setState({
											cancelModalVisible: true,
											operationInProgress: true,
										});
									}}
									disabled={this.state.operationInProgress}
								>
									Cancel Request
								</Button>}
						</Grid.Item>
						<Grid.Item />
						<Grid.Item>
							<ReactButton
								variant="primary"
								disabled={!this.formModel.isValid() || this.state.operationInProgress}
								isLoading={this.state.operationInProgress}
								handleUpdateSubmit={this.handleUpdate}
								loadingVariant="secondary"
								name="Save & Close"
							/>
						</Grid.Item>
						<Grid.Item>
							<ReactButton
								variant="primary"
								disabled={!this.isValid() || this.state.operationInProgress}
								isLoading={this.state.operationInProgress}
								handleUpdateSubmit={this.handleSubmit}
								loadingVariant="secondary"
								name="Submit Request"
							/>
						</Grid.Item>
					</Grid>
				</RequestFormWrapper>
				<ConfirmModal
					visible={this.state.cancelModalVisible}
					title={`Cancel POR #${this.props.draftRequest?.id}`}
					question="Are you sure you want to cancel this request? This action cannot be undone."
					confirmButton={{
						label: 'Cancel Request',
						props: {
							variant: 'primary',
							color: 'negative'
						}
					}}
					onConfirm={this.handleCancel}
					onCancel={() => {
						this.setState({
							cancelModalVisible: false,
							operationInProgress: false
						});
					}}
				/>
				<ConfirmModal
					visible={this.state.changeAccountModalVisible}
					title="Change Account"
					question="Are you sure you want to continue? If so, the ruleset will be cleared."
					confirmButton={{
						label: 'Yes',
					}}
					onConfirm={this.confirmAccountChange}
					onCancel={() => {
						this.setState({
							changeAccountModalVisible: false,
						});
					}}
				/>
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.endAndRedirect}
				/>
			</>
		);
	}
}

const AWSPortOpeningRequestsCreate = (props: AWSPortOpeningRequestsCreateProps) => {

    return <AWSPortOpeningRequestsCreateWithParams
		accountId={props.accountId}
		draftRequest={props.draftRequest}
		validNets={props.validNets}
		location={useLocation()}
		navigate={useNavigate()}
		params={useParams()}/>;
};

export default AWSPortOpeningRequestsCreate;
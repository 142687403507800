import * as React from 'react';
import { Modal, Field, Grid, XFilledIcon, Button, Alert, ProgressBar} from 'react-unity';
import ReadonlyField from '../../common/form-controls/ReadonlyField';
import UserLookup from '../../common/form-controls/UserLookup';
import { useEffect, useState } from 'react';
import UserLookupObject from '../../../models/UserLookupObject';
import ReactButton from '../../common/ReactButton';
import TemporaryAccessPasswordService from '../../../services/TemporaryAccessPasswordService';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import AlertModal from '../../common/modals/AlertModal';

interface TAPModalDelegationProps {
  visible: boolean,
  setVisible:any
  tapService: TemporaryAccessPasswordService;
  refreshTap:any;
}

const TAPModalDelegation = ({visible, setVisible, tapService, refreshTap}:TAPModalDelegationProps) => {
    
  const [delegatedUser, setDelegatedUser] = useState<UserLookupObject>();
  const [tapUser, setTapUser] = useState<UserLookupObject>();
  const [enableRequestButton, setEnableRequestButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerifyingSup, setIsVerifyingSup] = useState<boolean>(false);
  const [tap, settap] = useState<string>('');
  const [ alertBanner, setAlertBanner ] = useState<AlertBanner>({
    visible: false,
    variant: 'default'
	});

  useEffect(() => {
    settap('');
    enableRequest();
  }, [tapUser]);

  const endAndRedirect = () => {
		setAlertBanner({ visible: false });
	};

  const handleRequest = async () => {
    try {
      setIsLoading(true);
      const response = await tapService.submitTapOnBehafDelegated(delegatedUser.id, tapUser.id);
      settap(response.delegated.displayName);
      if (response != null) {
        setEnableRequestButton(false);
        setAlertBanner({
          visible: true,
          text: `TAP Delegated succesfully created`,
          variant: 'success'
        });
      } else {
        setAlertBanner({
          visible: true,
          text: `TAP could not be generated, please try again or submit a feedback for getting assistance`,
          variant: 'error'
        });
      }
      setIsLoading(false);
    }
    catch (err) {
      setIsLoading(false);
      settap('');
      setAlertBanner({
        visible: true,
        text: err.response.data.message,
        variant: 'error'
      });
    }
  }

  const handleClose = async () => {
    if (tap != '') {
      refreshTap(true);
    }
    setVisible(false);
  }

  //Validates if requestor is the supervisor of the selected employee
  const enableRequest = async () => {
    try {
      if (tapUser) {
        setIsVerifyingSup(true);
        await tapService.getSupVerifycation(tapUser.userPrincipalName);
        setIsVerifyingSup(false);
        setEnableRequestButton(true);
      } else {
        setEnableRequestButton(false);
        setIsVerifyingSup(false);
      }
    }
    catch (err) {
      setEnableRequestButton(false);
      setIsVerifyingSup(false);
      setAlertBanner({visible: true, text: err.response.data.message, variant: 'error'});
    }
  }


    return (
      <Modal show={visible}
        onHide={() => { }}>
        <Modal.Window id="TAPModalOnBehalfOf" className="em-u-margin-double">
          <Modal.Window.Header>
            <Modal.Window.Header.Title>Delegate TAP request</Modal.Window.Header.Title>
            <Modal.Window.Header.CloseButton onClick={() => setVisible(false)}>
              <XFilledIcon size='small' />
            </Modal.Window.Header.CloseButton>
          </Modal.Window.Header>
          <Modal.Window.Body>
            {(isLoading || isVerifyingSup ) ?
              <ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={isLoading ? 'Getting TAP' : 'Verifying Supervisor'} />
              :
              <>
                <UserLookup
                  id="employee-lookup"
                  label="On Behalf Of:"
                  note={'You must be the Supervisor of the selected employee'}
                  value={tapUser?.displayName}
                  onChange={(value) => setTapUser(value)} />
                <AlertModal {...alertBanner} willTimeout={false} onClose={endAndRedirect} />
                <Field>
                  <Field.Body>
                    <UserLookup
                        id="delegated-user-lookup"
                        label="Delegated User:"
                        note={'Select the employee to delegate TAP request'}
                        value={delegatedUser?.displayName}
                        onChange={(value) => setDelegatedUser(value)} 
                    />
                  </Field.Body>
                </Field>
              </>
            }
            <Alert
              variant='warning'
            >
                Delegations will be valid for 7 days, after which point, they will automatically expire. If the supervisor needs to change the delegate for a particular user, they can cancel the request and submit a new one. 
            </Alert>
          </Modal.Window.Body>
          <Modal.Window.Footer>
            <Grid variant="2-up">
              <Grid.Item style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                <Button
                  variant="secondary"
                  onClick={() => handleClose()}
                  disabled = {isLoading}
                >
                  Close
                </Button>
              </Grid.Item>
              <Grid.Item style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                <ReactButton
                  isLoading={isLoading || isVerifyingSup}
                  variant="primary"
                  handleUpdateSubmit={() => handleRequest()}
                  disabled={!enableRequestButton}
                  name='Request'
                  loadingVariant='secondary'
                >
                </ReactButton>
              </Grid.Item>
            </Grid>
          </Modal.Window.Footer>
        </Modal.Window>
      </Modal>
    );
}

export default TAPModalDelegation;

import User from "../User";

export default class TAPDelegation {

	delegated!: User;

	delegatedId!: number;

	supervisor!: User;

	tapUser!: User;

	createdDate!: Date;

	delegatedStatus!: string;

	constructor(obj: any) {
		if (obj != ''){
			Object.assign(this, obj);
		}
	}
}
import React from 'react';
import { WorkflowState } from '../../../models/enums/WorkflowState';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import WorkflowStateBadge from '../../common/WorkflowStateBadge';
import './ProgressBarBig.css';
import AWSPortOpeningRequest from '../../../models/entities/AWSPortOpeningRequests';

interface ProgressBarBigProps {
	workflowState: WorkflowState;
	por: AWSPortOpeningRequest;
}

const ProgressBarBig = ({ workflowState, por }: ProgressBarBigProps) => {
	const hasProgressBar = (): boolean => {
		const noProgressArray = [WorkflowState.Cancelled, WorkflowState.Rejected];
		return !noProgressArray.some((nws) => nws.value === workflowState.value);
	};

	const activeAOApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingSolutionArchitectureApproval.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.PendingForCyberApproval.value:
			case WorkflowState.PendingForTechnicalReview.value:
			case WorkflowState.PendingTechnicalApproval.value:
			case WorkflowState.Error.value:
				return "aws-big-back-blue";
			default:
				return "";
		}
	};

	const activeTechnicalApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingSolutionArchitectureApproval.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.PendingForCyberApproval.value:
			case WorkflowState.Error.value:
				return "aws-big-back-blue";
			default:
				return "";
		}
	};

	const activeDOAGApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.Error.value:
				return "aws-big-back-blue";
			default:
				return "";
		}
	};

	const activeArchitectApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.PendingForCyberApproval.value:
			case WorkflowState.PendingForCyberApproval.value:
			case WorkflowState.Error.value:
				return !por.architectApprovalBypassed() ? "aws-big-back-blue" : "aws-big-back-gray";
			default:
				return "";
		}
	};


	const activeCyberApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.Error.value:
				return !por.cyberApprovalBypassed() ? "aws-big-back-blue" : "aws-big-back-gray";
			default:
				return "";
		}
	};

	return (
		<>
			<div className="aws-big-progress-bar__title">
				<span className="em-c-field__label em-c-field__label--margin">Status</span>
				<WorkflowStateBadge workflowState={workflowState} />
			</div>
			{hasProgressBar() && (
				<div className="aws-big-progress-bar">
					<div className="aws-big-progress-bar__line" />
					<div className="aws-big-progress-bar__circle-with-title aws-big-left-0">
						<div className={`aws-big-progress-bar__circle ${activeAOApproval()}`}>✓</div>
						<div className="aws-big-progress-bar__circle--title">AO Approval</div>
					</div>
					<div className="aws-big-progress-bar__circle-with-title aws-big-left-50">
						<div className={`aws-big-progress-bar__circle ${activeTechnicalApproval()}`}>✓</div>
						<div className="aws-big-progress-bar__circle--title">Technical Approval</div>
					</div>
					<div className="aws-big-progress-bar__circle-with-title aws-big-left-100">
						<div className={`aws-big-progress-bar__circle ${activeArchitectApproval()}`}>✓</div>
						<div className="aws-big-progress-bar__circle--title">Network Policy Sol. Arch. Approval</div>
					</div>
					<div className="aws-big-progress-bar__circle-with-title aws-big-left-150">
						<div className={`aws-big-progress-bar__circle ${activeCyberApproval()}`}>✓</div>
						<div className="aws-big-progress-bar__circle--title">Cyber Approval</div>
					</div>
					<div className="aws-big-progress-bar__circle-with-title aws-big-left-200">
						<div className={`aws-big-progress-bar__circle ${activeDOAGApproval()}`}>✓</div>
						<div className="aws-big-progress-bar__circle--title">DOAG Approval</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ProgressBarBig;

import * as React from 'react';
import {
	Modal,
	Button,
	Grid,
	XFilledIcon
} from 'react-unity';
import './RuleModal.css';
import TextAreaField from '../../../common/form-controls/TextareaField';
import ToggleField from '../../../common/form-controls/ToggleField';
import TextInputField from '../../../common/form-controls/TextInputField';
import { AbstractRulesForm, IRuleStateForm, RulesFormProps } from './AbstractRulesForm';
import { RuleAction } from '../../../../models/enums/POR/RuleAction';
import { TrafficDirection } from '../../../../models/enums/POR/TrafficDirection';
import SelectField from '../../../common/form-controls/SelectField';
import { RuleRegion } from '../../../../models/enums/POR/RuleRegion';
import { RuleOperation } from '../../../../models/enums/POR/RuleOperation';
import { PortOpeningRequestsDirection } from '../../../../models/enums/POR/PortOpeningRequestsDirection';
import DatePickerField from '../../../common/form-controls/DatePickerField';
import moment from 'moment';

interface RuleModalSubToOnPremProps extends RulesFormProps{
	subscriptionRequestId: number;
	subscriptionRequestName: string;
}

interface SubToOnPremRuleStateForm extends IRuleStateForm {
	name: string;
    trafficDirection: TrafficDirection;
    protocol: string;
}
interface RuleModalSubToOnPremState {
	stateForm: SubToOnPremRuleStateForm;
}

export default class RuleModalSubToOnPrem extends AbstractRulesForm<
	RuleModalSubToOnPremProps,
	RuleModalSubToOnPremState
> {

	constructor(props: RuleModalSubToOnPremProps) {
		super(props);
		this.state = {
			stateForm: this.props.isFromHistory ? this.getRuleCopy() : this.getDefaultForm(),
		};

		this.initFormModel();
	}

	initFormModel() {
		super.initFormModel();
		this.formModel.addField('name', {
			getValue: () => this.state.stateForm.name,
			validation: {
				required: true,
				rules:
					[
						{
							assert: () => this.state.stateForm.operation !== RuleOperation.Add
							|| this.state.stateForm.name?.length <= 80,
							message: 'Name cannot be longer than 80 characters.',
						},
						{
							assert: () => !this.state.stateForm.name?.includes(' '),
							message: 'Name cannot contain spaces.',
						},
						{
							assert: () => /^(?![-_.])[a-zA-Z][a-zA-Z0-9-_.]*(?<![-_.])$/.test(this.state.stateForm.name ?? ''),
							message: 'Name must begin with a letter, end with a letter or number, and may contain only letters, numbers, underscores, periods or dashes.',
						}
					]
			}
		});

		this.formModel.addField('trafficDirection', {
			getValue: () => this.state.stateForm.trafficDirection,
			validation: {
				required: true,
			},
		});

		this.formModel.addField('protocol', {
			getValue: () => this.state.stateForm.protocol,
			validation: {
				required: true
			},
		});

		this.formModel.fields.destinationPorts.validation.rules.push(
			this.PortInputValidationRule({
				minimumPort: 0
			})
		);

		this.formModel.addField('description', {
			getValue: () => this.state.stateForm.description
		});
	}

	// eslint-disable-next-line class-methods-use-this
	getDefaultFormState() {
		return {
			isTemporary: false,
			expirationDate: null,
			operation: RuleOperation.Add,
			action: RuleAction.Allow,
			name: '',
			trafficDirection: null,
			protocol: 'Any'
		};
	}

	getRegionOptions(): RuleRegion[] {
		var subToOnPremRegions = new RuleRegion().listByDirection(PortOpeningRequestsDirection.SubscriptionToOnPrem, this.props.validVirtualNetworks, this.props.subscriptionRequestId);
		const wwSubscriptionsNames = ['APPS_SharedSvcs_WW_DEV', 'APPS_SharedSvcs_WW_LAB', 'APPS_SharedSvcs_WW_PRD'];
		if(wwSubscriptionsNames.includes(this.props.subscriptionRequestName))
			subToOnPremRegions.push(RuleRegion.WW);
		return subToOnPremRegions;
	}

	resetIPsValidations(){
		this.formModel.fields.sourceIPs.validation.rules = [];
		this.formModel.fields.destinationIPs.validation.rules = [];
	}

	handleTrafficDirection(event){
		this.handleStateFormChange('trafficDirection', new TrafficDirection().fromValue(event.target.value));
		//0 = In ; 1 == Out
		this.resetIPsValidations();
		if(this.state.stateForm.trafficDirection?.value == 1){
			this.formModel.fields.sourceIPs.validation.rules.push(
				this.IpInputValidationRule({
					onlyPrivateAddresses: true
				}),
				this.IpIsInAddressSpacesRule()
			);
			this.formModel.fields.destinationIPs.validation.rules.push(
				this.IpInputValidationRule({
					onlyPrivateAddresses: true
				})
			);
		}else{
			this.formModel.fields.sourceIPs.validation.rules.push(
				this.IpInputValidationRule({
					onlyPrivateAddresses: true
				}),
			);
			this.formModel.fields.destinationIPs.validation.rules.push(
				this.IpInputValidationRule({
					onlyPrivateAddresses: true
				}),
				this.IpIsInAddressSpacesRule()
			);
		}
	}

	wasEditted() {
		if(this.props.editingRule){
			return (
				this.props.editingRule.description != this.stateFormHandler().description.value
				|| this.props.editingRule.isTemporary != this.stateFormHandler().isTemporary.value
				|| this.props.editingRule.action.value != this.stateFormHandler().action.value			
				|| this.props.editingRule.sourceIPs != this.stateFormHandler().sourceIPs.value
				|| this.props.editingRule.destinationIPs != this.stateFormHandler().destinationIPs.value
				|| this.props.editingRule.destinationPorts != this.stateFormHandler().destinationPorts.value
				|| this.props.editingRule.trafficDirection != this.state.stateForm.trafficDirection
				|| this.props.editingRule.protocol != this.state.stateForm.protocol
				|| (this.props.editingRule.operation.value == RuleOperation.Add.value && (this.props.editingRule.name != this.state.stateForm.name))
		)}
		return true
	}

	render() {
		return (
			<Modal show={this.props.visible}
            onHide={() => { }}>
				<Modal.Window className="ruleModal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							{this.props.isReadOnly ? 'View Rule' : !this.isModalForNewRule() ? 'Edit Rule' : 'New Rule'}
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						{ !this.isModalForNewRule() &&
							<ToggleField
								{...this.stateFormHandler().operation}								
								label="Operation"
								disabled={this.props.isReadOnly}
								options={this.props.isReadOnly ?
									[
										new RuleOperation().fromValue(this.stateFormHandler().operation.value)
									]									
									:!this.stateFormHandler().isTemporary.value ?
									[
										RuleOperation.Modify,
										RuleOperation.Remove
									]:
									[
										RuleOperation.Remove
									]
								}
							/>
						}
						<Grid variant="4-up">
									<Grid.Item>
										<ToggleField
											{...this.stateFormHandler().isTemporary}
											value={this.state.stateForm.isTemporary?.toString()}
											options= {(this.formCanBeEdited())? [
												{ text: 'Temporary', value: 'true' },
												{ text: 'Permanent', value: 'false' },
												]
												:this.state.stateForm.isTemporary?
												[
												{ text: 'Temporary', value: 'true' }]
												:
												[{ text: 'Permanent', value: 'false' },
											]}
											label="Type"
											disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<DatePickerField style={{ visibility: this.stateFormHandler().expirationDate.value ? 'visible' : 'hidden' }}
											{...this.stateFormHandler().expirationDate}											
											value={ this.stateFormHandler().expirationDate.value ? moment(this.stateFormHandler().expirationDate.value).format('MM-YYYY-DD'):''}
											disabled= {true}
										/>
									</Grid.Item>
						</Grid>
						<Grid variant="2-up">
							<Grid.Item>	
								<TextInputField
									label="Rule Name"
									value={this.state.stateForm.name}
									validation={this.formModel.fields.name.validation}
									onChange={
										(event) => {
											this.handleStateFormChange('name', event.target.value);
										}
									}
									disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
								/>
							</Grid.Item>
							<Grid.Item>
								<SelectField
									{...this.stateFormHandler().region}
									options={this.getRegionOptions()}
									disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
								/>
							</Grid.Item>
						</Grid>
							<>
								<Grid variant="2-up">
									<Grid.Item>
										<ToggleField
											{...this.stateFormHandler().action}
											options= { (this.formCanBeEdited())? [
												RuleAction.Allow,
												RuleAction.Block,
											]:this.stateFormHandler().action?.value?
											[RuleAction.Allow]
											:
											[RuleAction.Block]
											}
											label="Action"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<SelectField
											label="Traffic Direction"
											value={this.state.stateForm.trafficDirection?.value}
											options={[
												{ text: 'Inbound (XOMNetwork -> Subscription)', value: TrafficDirection.In.value },
												{ text: 'Outbound (Subscription -> XOMNetwork)', value: TrafficDirection.Out.value },
											]}
											className="full-width"
											onChange={(event) => this.handleTrafficDirection(event)}
											validation={this.formModel.fields.trafficDirection.validation}
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().sourceIPs}
											label="Source (IPs / IP Range / CIDR)"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().destinationIPs}
											label="Destination (IPs / IP Range / CIDR)"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().destinationPorts}
											label="Destination Ports"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item />
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().description}
											label="Description"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<SelectField
											label="Protocol"
											value={this.state.stateForm.protocol}
											options={['Any', 'TCP', 'UDP', 'ICMP']}
											onChange={(event) => this.handleStateFormChange('protocol', event.target.value)}
											validation={this.formModel.fields.protocol.validation}
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
							</>
					</Modal.Window.Body>
					{ !this.props.isReadOnly &&
						<Modal.Window.Footer>
							<Grid variant="2-up">
								<Grid.Item>
									<Button
										variant="primary"
										disabled={(!this.formModel.isValid() || !this.wasEditted()) && this.state.stateForm.operation.name !== RuleOperation.Remove.name}
										onClick={this.handleRequest}
									>
										{this.props.editingRule ? 'Confirm' : 'Create'}
									</Button>
								</Grid.Item>
								<Grid.Item>
									<Button
										variant="secondary"
										onClick={this.handleCancel}
									>
										Cancel
									</Button>
								</Grid.Item>
							</Grid>
						</Modal.Window.Footer>
					}
				</Modal.Window>
			</Modal>
		);
	}
}
import React, { useState, useEffect } from 'react';
import ResourceTabs from '../common/ResourceTabs';
import TAP from '../../models/entities/TAP';
import Filter from '../common/tables/Filter';
import { Button, Searchbar, Toolbar } from 'react-unity';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';
import CSVGeneratorButton from '../common/CSVGeneratorButton';
import ExportColumns from './components/TAPExport';
import { isEmptyArray } from 'formik';
import ReactButton from '../common/ReactButton';
import TAPDelegation from '../../models/entities/TAPDelegation';
import CheckboxFilter, { CheckboxOption } from '../common/tables/CheckboxFilter';

type TAPsFilters = {
    searchGeneral: string;
    status: CheckboxOption[];
    dateRangeFrom: Date;
    dateRangeTo: Date;
    showResources: string;
};

interface TemporaryAccessPassFiltersProps {
    approvalsInfo: TAP[];
    onBehalfOfInfo: TAP[];
    handleOnBehalfOfSearchInfo: any;
    tapInfo: TAP[];
    delegationInfo: TAPDelegation[];
    isTapDataLoading: boolean;
    areMyApprovalsLoading: boolean;
    setFileteredTAPS: any;
    tab: string;
    setTab: any;
    filteredTAPS:TAP[];
    setSearchEmailIsLoading:any;
    searchEmailIsLoading:boolean;
}

const TemporaryAccessPassFilters = ({
    approvalsInfo,
    onBehalfOfInfo,
    handleOnBehalfOfSearchInfo,
    tapInfo,
    delegationInfo,
    isTapDataLoading,
    areMyApprovalsLoading,
    setFileteredTAPS,
    tab,
    setTab,
    filteredTAPS,
    searchEmailIsLoading
}: TemporaryAccessPassFiltersProps) => {
    const resourceTabs = [];
    resourceTabs.push({
        item1: `My TAP Requests`,
        item2: null,
    });
    resourceTabs.push({
        item1: `My Approvals`,
        item2: null
    });
    resourceTabs.push({
        item1: `On Behalf Of`,
        item2: null
    });
    resourceTabs.push({
        item1: `Delegations`,
        item2: null
    });
    const defaultFilters = {
        searchGeneral: '',
        status: [
			{ label: 'Enabled', value: 'Enabled', checked: true },
			{ label: 'Disabled', value: 'Disabled', checked: true },
		],
        dateRangeFrom: new Date('January 01, 2000 23:15:30'),
        dateRangeTo: new Date(),
        showResources: `My TAP Requests`,
    } as TAPsFilters;

    const filterTAPSRequests = () => {
        let filtered = [];

        const filterCallback = (tap: TAPDelegation) => {
			return (
				filter.filterCheckBox('status', tap.delegatedStatus) &&
				(filter.filterSearchText('searchGeneral', tap.delegated?.displayName) ||
					filter.filterSearchText('searchGeneral', tap.delegated?.email.address) ||
					filter.filterSearchText('searchGeneral', tap.supervisor?.displayName) ||
					filter.filterSearchText('searchGeneral', tap.supervisor?.email.address) ||
					filter.filterSearchText('searchGeneral', tap.tapUser?.displayName) ||
					filter.filterSearchText('searchGeneral', tap.tapUser?.email.address)
				) &&
				filter.filterDateRange('dateRangeFrom', 'dateRangeTo', tap.createdDate)  
				);
		}; 

        if (filter.filters.showResources == 'My Approvals') {
            setTab('My Approvals');
            filtered = approvalsInfo;
        } else if (filter.filters.showResources == 'My TAP Requests'){
            setTab('My TAP Requests');
            filtered = tapInfo;
        } else if (filter.filters.showResources == 'Delegations'){
            setTab('Delegations');
            filtered = delegationInfo.filter(filterCallback);
        }else {  
            setTab('On Behalf Of');
            filtered = onBehalfOfInfo;
        }
        setFileteredTAPS(filtered);
    };
   
    const [searchEmail, setSearchEmail] = useState<string>('');

    useEffect(() => {
        filter.updateFilterFunction(filterTAPSRequests);
    }, [tapInfo]);

    useEffect(() => {
        filter.filter();
        filter.updateFilter(tab, 'showResources')
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
		handleOnBehalfOfSearchInfo(searchEmail);
	};

    const handedisabledSearchBar = () => {
        return searchEmail == '';
	};

    const [filter] = useState<Filter<TAPsFilters>>(new Filter(filterTAPSRequests, defaultFilters));
    
    const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchGeneral');
	};

    return (
        <>
            {filter && (
                <>
                    <ResourceTabs
                        allResources={resourceTabs}
                        filter={filter}
                        disabled={isTapDataLoading || areMyApprovalsLoading}
                    />
                    {(authenticatedUser.isInRole(UserRole.TAPReportUser) && tab == 'On Behalf Of') && <Toolbar id="toggle-toolbar">
                        <Toolbar.Item className="searchbar-width">
                            <Searchbar
                                condensed
                                buttonProps={{ 
                                    onClick: handleSearch,
                                }}
                                inputProps={{
                                    value: searchEmail,
                                    onChange: (e) => setSearchEmail(e.target.value),
                                    placeholder: 'Search by Supervisor email',
                                }}
                            />
                        </Toolbar.Item>
                        <Toolbar.Item >
                            <ReactButton
                                variant="primary"
                                size="small"
                                handleUpdateSubmit={() => {
                                    setSearchEmail('');
                                }}
                                name={'Clear Filter'}
                                disabled={handedisabledSearchBar()}
                                isLoading={searchEmailIsLoading}
                                loadingVariant="secondary"
                            />
                        </Toolbar.Item >
                        <Toolbar.Item right>
                            <CSVGeneratorButton data={filteredTAPS} columns={ExportColumns} isLoading={searchEmailIsLoading} disabled={isEmptyArray(filteredTAPS)}/>
                        </Toolbar.Item>
                    </Toolbar>}
                    {(tab == 'Delegations') && <Toolbar id="toggle-toolbar">
                        <Toolbar.Item className="searchbar-width">
                            <Searchbar
                                condensed
                                buttonProps={{ onClick: clearSearchbar }}
                                inputProps={{
                                    value: filter.filters.searchGeneral,
                                    onChange: (e) => filter.handleSearchTextChange(e, 'searchGeneral'),
                                    placeholder: 'Search by name or email',
                                }}
                            />
                        </Toolbar.Item>
                       <Toolbar.Item>
							<CheckboxFilter
								label="Status"
								buttonProps={{ size: 'small' }}
								onChange={(event) => filter.handleCheckboxChange(event, 'status')}
								options={filter.filters.status}
							/>
						</Toolbar.Item>
                    </Toolbar>}
                </>
            )}
        </>
    );
};

export default TemporaryAccessPassFilters;

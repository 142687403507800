import * as React from 'react';
import { Footer as UnityFooter } from 'react-unity';

export default class Footer extends React.Component {

	LinkTo = (url: string, label: string) => (
		<UnityFooter.Link
			className="em-u-clickable"
			onClick={() => window.open(url, '_blank')}
		>
			{label}
		</UnityFooter.Link>
	);

	render() {
		return (
			<UnityFooter>
				<UnityFooter.Link>
						RELATED LINKS
				</UnityFooter.Link>
				<UnityFooter.Link>
						EXPLORE EMIT
				</UnityFooter.Link>
				{this.LinkTo('https://goto/cloud', 'goto/cloud')}
				{this.LinkTo('https://goto/AppsHub', 'Applications Hub')}
				{this.LinkTo('https://portal.azure.com', 'Azure Portal')}
			</UnityFooter>
		);
	}
}
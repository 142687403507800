import * as React from 'react';
import {
	Table,
	Toolbar,
	Field,
	Button,
	PlusFilledIcon,
	TrashcanIcon,
	PencilIcon,
	Loader,
	Badge,
} from 'react-unity';
import PortOpeningRule from '../../../models/entities/PortOpeningRule';
import { RulesFormProps } from './porRule/AbstractRulesForm';
import RuleModalSubToInternet from './porRule/RuleModalSubToInternet';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import RuleModalSubToOnPrem from './porRule/RuleModalSubToOnPrem';
import CSVGeneratorButton from '../../common/CSVGeneratorButton';
import BulkUploadModal from './porRule/BulkUploadModal';
import Paginator from '../../common/tables/Paginator';
import { PORColumn, RuleColumnsLayout } from './RuleColumnsLayout';
import ImplementedRulesModal from './ImplementedRulesModal';
import { VirtualNetwork } from '../../../models/entities/AzureResources/VirtualNetwork';
import POROperationBadge from './POROperationBadge';
import PORTypeBadge from './PORTypeBadge';
import TrashcanModal from './TrashcanModal';
import { RuleOperation } from '../../../models/enums/POR/RuleOperation';
import Select, { StylesConfig } from "react-select";
import './RulesTable.css';
import { WorkflowState } from '../../../models/enums/WorkflowState';

interface RulesTableProps {
	subscriptionRequestName?: string;
	subscriptionRequestId?: number;
	rules: PortOpeningRule[];
	implementedRules?: PortOpeningRule[];
	validVirtualNetworks?: VirtualNetwork[];
	porDirection: PortOpeningRequestsDirection;
	readonly?: boolean;
	onChange: (rules: PortOpeningRule[]) => any;
	loadingImplementedRules?: boolean;
	failedImplementedRules?: boolean;
	selectedFromExisting?: string[];
	setSelectedFromExisting?: (rule: string[]) => any;
	fromDetails?: boolean;
	currentWorkflowState?: number;
}

interface RulesTableState { 
	columns: PORColumn[];
	ruleModalVisible: boolean;
	editingRule?: PortOpeningRule;
	bulkUploadModalVisible: boolean;
	pageRows: PortOpeningRule[];
	implementedRuleModalVisible: boolean;
	optionValueSelected: string,
	showTrashcanModal: boolean;
}

export class RulesTable extends React.Component<RulesTableProps, RulesTableState> {
	
	constructor(props: RulesTableProps | Readonly<RulesTableProps>) {
		super(props);
		this.state = {
			columns: [],
			ruleModalVisible: false,
			bulkUploadModalVisible: false,
			pageRows: [],
			implementedRuleModalVisible: false,
			showTrashcanModal: false,
			optionValueSelected: "",
		};
	}

	componentDidMount() {
		this.setColumns();
	}

	componentDidUpdate(prevProps: RulesTableProps) {
		if (prevProps.porDirection?.value !== this.props.porDirection?.value) {
			this.setColumns();
		}
	}

	setColumns() {
		this.setState({
			columns: RuleColumnsLayout.get(this.props.porDirection)
		});
	}

	getRulesModalProps(): RulesFormProps {
		let editingRule = null;
		if (this.state.editingRule != null) {
			editingRule = {...this.state.editingRule};
			if ((editingRule.isTemporary && editingRule.operation == RuleOperation.Modify)) editingRule.operation = RuleOperation.Remove; 
			editingRule.name = editingRule.azureName ?? editingRule.name;
		}
		return {
			visible: this.state.ruleModalVisible,
			onConfirm: this.handleRuleChange,
			onClose: () => this.setState({ editingRule: null, ruleModalVisible: false }),
			editingRule: editingRule,
			validVirtualNetworks: this.props.validVirtualNetworks
		};
	}

	handleRuleChange = (rule: PortOpeningRule) => {
		let { rules } = this.props;
		if (this.state.editingRule){
			const index = rules.indexOf(this.state.editingRule);
			rules = [...rules];
			rules[index] = rule;
		} else {
			rules = [...rules, rule];
		}
		this.props.onChange(rules);
		this.setState({ ruleModalVisible: false, editingRule: null });
	};

	handleDeleteAll = () => {
		this.props.onChange([]);
		this.resetTrashcanModal();
	};

	handleDeleteOrRemoveRule = (ruleToRemove: PortOpeningRule) => {
		this.setState({ showTrashcanModal: true, editingRule: ruleToRemove})
	};

	handleRemoveRule = () => {
		const rules = this.props.rules.filter(rule => rule !== this.state.editingRule);
		this.props.setSelectedFromExisting(this.props.selectedFromExisting.filter(ruleName => ruleName !== this.state.editingRule.name));
		this.props.onChange(rules);
		this.resetTrashcanModal();
	};

	handleDeleteRule = () => {
		let editingRule = {...this.state.editingRule};
		editingRule.name = editingRule.azureName ?? editingRule.name;
		editingRule.azureName = undefined;
		editingRule.operation = RuleOperation.Remove;
		this.handleRuleChange(editingRule);
		this.resetTrashcanModal();
	};


	resetTrashcanModal = () => {
		this.setState({
			editingRule: null,
			showTrashcanModal: false
		})
	}

	closeTrashcanModal = this.resetTrashcanModal;

	handleBulkUpload = (rules: PortOpeningRule[]) => {
		this.setState({
			bulkUploadModalVisible: false,
			optionValueSelected: '',
		});
		this.props.onChange([...this.props.rules, ...rules]);
	};

	handlePageChange = (page: PortOpeningRule[]) => {
		this.setState({
			pageRows: page
		});
	};

	handleImplementedRulesOnSuccess = (impRules: PortOpeningRule[]) => {
		this.setState({
			implementedRuleModalVisible: false,
			optionValueSelected: '',
		});
		this.props.setSelectedFromExisting([...this.props.selectedFromExisting, ...impRules.map(rule => rule.name)])
		this.props.onChange([...this.props.rules, ...impRules]);
	};
	
	directionIs(direction: PortOpeningRequestsDirection) {
		return this.props.porDirection === direction;
	}

	getOperationName(rule: PortOpeningRule) {
		if (this.props.fromDetails && this.props.currentWorkflowState !== WorkflowState.Review.value && this.props.currentWorkflowState !== WorkflowState.Draft.value){
			return this.state.columns.find(column => column.header === 'Operation').cell(rule)
		}
		const operationColumn = this.state.columns.find(column => column.header === 'Operation')
		return operationColumn.cell(rule)
		
	}

	handleOptionSelected = (option) => {
		this.setState({ optionValueSelected: option.value });
		if(option.value === "import-new-rules"){
			if (!option.isDisabled) this.setState({ bulkUploadModalVisible: true });
		}else{
			if (!option.isDisabled) this.setState({ implementedRuleModalVisible: true });
		}
	}

	CustomOption = (props: { data: any; innerRef: any; innerProps: any;  isDisabled: boolean }) => {
		const { data, innerRef, innerProps, isDisabled } = props;
		return(
			<div
				style={{ cursor: `${isDisabled ? 'not-allowed' : 'pointer'}`}}
				ref={innerRef}
				{...innerProps}
				className='custom-option-box'
			>
				<div style={{ color: `${isDisabled ? '#999' : '#1E55B1'}`, fontSize: 'large', }}>{data.isLoading ? data.loadingMessage : data.label}</div>
				<div style={{ color: '#999', fontSize: 'small', wordWrap: 'break-word', width: '180px'}}>{data.subtext}</div>
			</div>
		)
	}

	styles: StylesConfig<any, false> = {
		control: (css) => ({ ...css, width: 200, zIndex: 5 }),
		};

	render() {
		const tableHeaders = this.state.columns?.filter(column => column.header !== "Protocol" && column.header !== "Description").map((column) => (
			<Table.Head.Cell key={column.header}>
				{column.header}
			</Table.Head.Cell>
		));
		const tableRows = [];
		const defaultLimit = 10;
		const options = [
			{
				value: "import-new-rules",
				label: 'Import new rules',
				subtext: 'Add new rules at the same time with CSV file',
				isDisabled: this.props.porDirection == null || !(this.props.validVirtualNetworks.length > 0),
				isLoading: this.props.porDirection == null || !(this.props.validVirtualNetworks.length > 0),
				loadingMessage: this.props.porDirection != null ? 'Loading Virtual Networks...' : 'Please select a POR Direction'
			},
			{
				value: "import-existing-rule",
				label: 'Import existing rules',
				subtext: 'Add existing rules to delete or modify from this subscritpion',
				isDisabled: this.props.loadingImplementedRules || !(this.props.implementedRules?.length > 0),
				isLoading: this.props.loadingImplementedRules || !(this.props.implementedRules?.length > 0),
				loadingMessage: (!(this.props.loadingImplementedRules) && this.props.implementedRules?.length == 0) ? 'No Rules Implemented' : 'Loading Existing Rules...'
			},
		];

		this.state.pageRows.forEach((rule, rowIndex) => {
			tableRows.push(
				<Table.Body.Row key={rowIndex}>
					<Table.Body.Cell>
						{this.props.rules.indexOf(rule) + 1}
					</Table.Body.Cell>
					{this.state.columns?.filter(column => column.header !== "Protocol" && column.header !== "Description").map((column, colIndex) => (
						<Table.Body.Cell
							key={`${rowIndex}-${colIndex}`}
							className="em-u-margin-right-auto"
							title={(column.cell(rule) || '-')}
						>
							{column.header === 'Operation' ? (<POROperationBadge>{this.getOperationName(rule)}</POROperationBadge>)
								: (column.header === 'Type' ? (<PORTypeBadge >{column.cell(rule)}</PORTypeBadge>)
									: (column.cell(rule)?.length > (column.limit || defaultLimit) ?
										(`${column.cell(rule).trim().substring(0, (column.limit || defaultLimit))}...`)
										:
										(column.cell(rule) || '-')))}
						</Table.Body.Cell>
					))}
						<Table.Body.Cell>
							{ !this.props.readonly ? 
								<Button.Group>
									<Button
										style={{zIndex: 0}}
										variant="primary"
										size="small"
										onClick={() => this.setState({ ruleModalVisible: true, editingRule: rule })}
									>
										<PencilIcon size="small" />
									</Button>
									<Button
										style={{zIndex: 0}}
										variant="primary"
										size="small"
										onClick={() => this.handleDeleteOrRemoveRule(rule)}
									>
										<TrashcanIcon size="small" />
									</Button>
								</Button.Group>
								:
								<Button
									style={{zIndex: 0}}
									variant="primary"
									size="small"
									onClick={() => this.setState({ ruleModalVisible: true, editingRule: rule })}
								>
									See details
								</Button>
							}
						</Table.Body.Cell>
				</Table.Body.Row>
			);
		});

		return (
			<>
				<Field>
					<Field.Label>
						Ruleset
					</Field.Label>
					<Field.Body>
						<Table
							header={
								<Toolbar>
									{this.props.readonly &&
										<Toolbar.Item right>
											<CSVGeneratorButton
												data={this.props.rules}
												columns={this.state.columns}
											/>
										</Toolbar.Item>}
									{!this.props.readonly &&
									<Toolbar.Item right>
										<Field inline size="small">
												<Button.Group>
													<Button
														variant="primary"
														size="small"
														disabled={!this.props.porDirection || !(this.props.validVirtualNetworks.length > 0)}
														onClick={() => this.setState({ ruleModalVisible: true })}
													>
														{!(this.props.validVirtualNetworks.length > 0) ?
															<>
																{(this.props.failedImplementedRules) ?
																	<>
																		<Loader className="icon-loader em-c-icon--small" />
																		<span>Operation Failed</span>
																	</>
																	:
																	<>
																		<Loader className="icon-loader em-c-icon--small" />
																		<span>Loading Virtual Networks...</span>
																	</>}
															</>
															:
															<>
																<PlusFilledIcon size="small" />
																<span>Add New Rule</span>
															</>}
													</Button>
													<Select
														placeholder='Import rules'
														value={this.state.optionValueSelected != null ? this.state.optionValueSelected : ''}
														onChange={(option) => this.handleOptionSelected(option)}
														components={{ Option: this.CustomOption }}
														options={options}
														styles={this.styles}
													/>
												</Button.Group>
										</Field>
									</Toolbar.Item>}
								</Toolbar>
							}
							footer={
								<Paginator
									data={this.props.rules}
									onPageChange={this.handlePageChange}									
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row>
									<Table.Head.Cell />
									{tableHeaders}
									<Table.Head.Cell>
										{!this.props.readonly ? 'Actions' : ''}
									</Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
							<Table.Body>
								{tableRows.length > 0 ?
									tableRows
									:
									<Table.Body.Row>
										<Table.Body.Cell
											colSpan={this.state.columns?.length + 2 || 2}
										>
											<i>No rules found.</i>
										</Table.Body.Cell>
									</Table.Body.Row>}
							</Table.Body>
						</Table>
					</Field.Body>
				</Field>
				{ this.state.showTrashcanModal &&
					<TrashcanModal
						onClose={this.resetTrashcanModal}
						onDelete={this.handleDeleteRule}
						onRemove={this.handleRemoveRule}
					/>
				}

				{ this.directionIs(PortOpeningRequestsDirection.SubscriptionToInternet) &&
					<RuleModalSubToInternet
						{...this.getRulesModalProps()}
						subscriptionRequestName={this.props.subscriptionRequestName}
						subscriptionRequestId = {this.props.subscriptionRequestId}
						isReadOnly={this.props.readonly}
					/>}
				{ this.directionIs(PortOpeningRequestsDirection.SubscriptionToOnPrem) &&
					<RuleModalSubToOnPrem
						{...this.getRulesModalProps()}
						subscriptionRequestName={this.props.subscriptionRequestName}
						subscriptionRequestId = {this.props.subscriptionRequestId}
						isReadOnly={this.props.readonly}
					/>}
				{this.state.implementedRuleModalVisible && 
					<ImplementedRulesModal
						direction={this.props.porDirection}
						rules={this.props.implementedRules}
						onSuccess={this.handleImplementedRulesOnSuccess}
						visible={this.state.implementedRuleModalVisible}
						alreadySelected={this.props.selectedFromExisting}

						onCancel={(ev) => {this.setState({
													implementedRuleModalVisible: false,
													optionValueSelected: '',
												});
											}}
						/>}
				<BulkUploadModal
					subscriptionRequestId={this.props.subscriptionRequestId}
					visible={this.state.bulkUploadModalVisible}
					direction={this.props.porDirection}
					onSuccess={this.handleBulkUpload}
					onCancel={(ev) => {
						this.setState({
							bulkUploadModalVisible: false,
							optionValueSelected: '',
						});
					}}
				/>
			</>
		);
	}
}
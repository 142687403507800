import React from 'react';
import { Modal, XFilledIcon, Button, Grid } from 'react-unity';
import AWSPortOpeningRule from '../../../models/entities/AWSPortOpeningRule';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import './AWSImplementedRulesModal.css';
import { RuleOperation } from '../../../models/enums/POR/RuleOperation';
import AWSImplementedRulesTable from './AWSImplementedRulesTable';

interface AWSImplementedRulesModalProps {
	direction: PortOpeningRequestsDirection;
    rules: AWSPortOpeningRule[];
	onSuccess: (rules: AWSPortOpeningRule[]) => any;
	onCancel: (...args: any[]) => any;
	visible: boolean;
	alreadySelected: string[]
}

interface AWSImplementedRulesModalState {
    selectedRules: AWSPortOpeningRule[];
}

export default class AWSImplementedRulesModal
	extends React.Component<AWSImplementedRulesModalProps, AWSImplementedRulesModalState> {

	constructor(props) {
		super(props);
		this.setState({
			selectedRules: []
		});
	}

	handleOnChange = (rules: AWSPortOpeningRule[]) => {
		this.setState({ selectedRules: rules });
	};
	
	handleOnSuccess = () => {
		const rules = this.state?.selectedRules;
		rules.forEach(rule => {
			rule.operation = RuleOperation.Modify;
			rule.name = rule.awsName;
		});
		this.setState({ selectedRules: [] });
		this.props.onSuccess(rules);
	};
	
	handleOnCancel = () => {
		this.setState({ selectedRules: [] });
		this.props.onCancel();
	};

	disableAddRulesButton(): boolean {
		return this.state?.selectedRules == null || this.state.selectedRules?.length === 0;
	}
	
	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window className="autoOverflowModal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							Add rules to the request
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.handleOnCancel}>
							<XFilledIcon size="small" />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<AWSImplementedRulesTable
							porDirection={this.props.direction}
							rules={this.props.rules}
							selectedRules={this.state?.selectedRules || []}
							onChange={this.handleOnChange}
						/>
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="halves">
							<Grid.Item>
								<Button
									variant="secondary"
									onClick={this.handleOnCancel}
								>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button
									variant="primary"
									onClick={this.handleOnSuccess}
									disabled={this.disableAddRulesButton()}
								>
									Add Rules
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}